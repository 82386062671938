<template>
  <div>
    <v-btn v-if="!timer" @click="ch" outlined color="primary">Великий рандом</v-btn>
    <v-btn v-else @click="ch" outlined color="primary">Стоп</v-btn>
    <div class="team" :key="team.id" v-for="(team, i) in teams">
      <div>
        <v-img height="64" width="64" :src="team.logo"/>
      </div>
      <div class="team-name">
        <div>
          <h4>{{ team.name }}</h4>
        </div>
        <div>
          {{ team.description }}
        </div>
      </div>
      <v-spacer/>
      <div class="team-place">
        {{ i + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamSorted",
  computed: {
    teams() {
      return this.$store.state.events.teams;
    }
  },
  data() {
    return {
      timer: null
    }
  },
  methods: {
    ch() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.$store.commit('setTeams', this.$store.state.events.teams.sort(() => .5 - Math.random()))
        }, 50);
      } else {
        clearInterval(this.timer)
      }

    }
  },
  mounted() {
    this.$store.dispatch('getTeams', {event_id: this.$route.params.id})
  }
}
</script>

<style scoped>
.team {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: #efefef;
  margin: 10px;
}

.team-name {
  margin-left: 20px;
}

.team-place {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #a8ebff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
